import React from "react";
import { DeviceInformationBar } from "./DeviceInformationBar";
import { useEffect, useState } from "react";
import { StoreState } from "src/reducers";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { DeviceModel } from "../../models/device.model";
import { Button } from "primereact/button";
import { DialogTypes, OpenDialogArgs } from "../general/CustomDialog";
import { DialogContext } from "../../contexts/DialogContext";
import { DialogPropTypes } from "../../contexts/types";
import { detachDevice, updateToast } from "../../actions";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { ToastModel, TOAST_SEVERITY } from "../../models/toast.model";

interface DeviceSettingsHeaderProps {
    devices?: DeviceModel[];
    detachDevice: Function;
    showToast: Function;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, void, Action>) => {
    return {
        detachDevice: (deviceId: string, callBack: Function) => {
            dispatch(detachDevice(deviceId, callBack));
        },
        showToast: (ToastModel: ToastModel) =>
        dispatch(updateToast(ToastModel))
    };
  };

const mapStateToProps = ({ deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices,
    };
};

const _DeviceSettingsHeader = ({
    devices,
    detachDevice,
    showToast
}: DeviceSettingsHeaderProps): JSX.Element => {
    const [currentDevice, setCurrentdevice] = useState<DeviceModel>();
    const [deviceId, setDeviceId] = useState<string>();
    const { t } = useTranslation();
    const navigate: NavigateFunction = useNavigate();
    const { showDialog } = React.useContext(DialogContext) as DialogPropTypes ;

    let params = useParams();

    useEffect(() => {
        let currentDeviceId = params.deviceId ? params.deviceId : "";
        setDeviceId(currentDeviceId);

        let device = devices?.find((device) => device.id === currentDeviceId);
        setCurrentdevice(device);
    }, [devices]);

    const moveToPatientView = () => {
        if (currentDevice?.id) {
            navigate(`/patientview/${currentDevice?.id}`);
        }
    };

    const openDialog = () => {
        if (!deviceId) {
            console.log("Device id is undefined");
            return
        }

        const args: OpenDialogArgs = {
            dialogType: DialogTypes.ACTION_DIALOG,
            title: t("detachPatient"),
            component: (
                <div>
                    <div>
                        {t("detachDialogDescription")}
                    </div>
                    <div>{t("areYouSure")}</div>
                </div>
            ),
            actionCallback: async () => {
                detachDevice(deviceId, ((result: boolean) => {
                    if (result) {
                        showToast({
                            severity: TOAST_SEVERITY.SUCCESS,
                            summary: t("Success"),
                            detail: t("The device was detached."),
                        });
                    }
                    else {
                        showToast({
                            severity: TOAST_SEVERITY.ERROR,
                            summary: t("Something went wrong!"),
                            detail: t("The device wasn't detached")
                        });  
                    }
                }));
            },
            cancelCallback: () => {
                console.log("Action Canceled");
            },
            actionText: t("reset"),
            cancelText: t("cancel"),
        };


        if (deviceId) {
            showDialog(args);
        }
    };

    return (
        <div className="device-settings-header">
            <div className="device-settings-header__top">
                <div className="device-settings-header__link">
                    <span className="device-settings-header__link-text" onClick={() => {navigate("/settings/devices")}}>
                        {t("Devices")}
                    </span>
                    {` > ${t("Device")} ${currentDevice?.serial ? currentDevice?.serial : ""}`}
                </div>
                <div className="device-settings-header__buttons">
                    <Button
                        onClick={() => openDialog()}
                        className="device-settings-header__detach-button
                        device-settings-header__button 
                        button button-regular"
                        disabled={currentDevice?.patient?._id ? false : true}
                    >
                        {t("Reset")}
                    </Button>
                    <Button
                        onClick={() => moveToPatientView()}
                        className="device-settings-header__button button button-regular"
                        disabled={currentDevice?.patient?._id ? false : true}
                    >
                        {t("Patient View")}
                    </Button>
                </div>
            </div>
            <div className="device-settings-header__device-info">
                <DeviceInformationBar device={currentDevice} />
            </div>
        </div>
    )
};

export const DeviceSettingsHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(_DeviceSettingsHeader);
