import React, { useState, useEffect } from "react";
import { formatFloatAsTime } from "../../utils";

interface TimerProps {
    isRunning: boolean;
    timeLimit: number;
}

const Timer: React.FC<TimerProps> = ({ isRunning, timeLimit }) => {
    const [playTimer, setPlayTimer] = useState<number>(0);

    useEffect(() => {
        let timeoutId: any;

        const handleTimeout = () => {
            setPlayTimer((prevTimer) =>
                timeLimit >= prevTimer + 1 ? prevTimer + 1 : prevTimer
            );
            timeoutId = setTimeout(handleTimeout, 1000);
        };

        if (isRunning) {
            timeoutId = setTimeout(handleTimeout, 1000);
        } else {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setPlayTimer(0);
            }
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [isRunning]);

    return <div>{formatFloatAsTime(playTimer / 60)}</div>;
};

export default Timer;
