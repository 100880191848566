import axios from 'axios';
import { getToken, refreshToken, getSessionId } from '../actions';

const shouldRetry = (originalRequestConfig: any) => {
    const RETRIES = 1;
    if (!originalRequestConfig.retryCounter) {
        originalRequestConfig.retryCounter = RETRIES;
    } else {
        originalRequestConfig.retryCounter++;
    }
    
    return originalRequestConfig.retryCounter < 3;
}

const interceptor = () => {
    axios.interceptors.request.use(
        (config) => {
            const token = getToken();
            const sessionId = getSessionId();

            if (token && config?.headers) {
                if(!config.headers.Authorization) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                if(!!sessionId && !config.headers.SessionId) {
                    config.headers['Session-id'] = sessionId;
                }
            }

            return config;
        }
    );

    axios.interceptors.response.use(
        (config) => {
            return config;
        },
        async (error) => {
            if (error.response?.status === 403) {
                const token = await refreshToken();
                const originalRequestConfig = error.config;
                originalRequestConfig.headers.Authorization = `Bearer ${token}`;
                if (shouldRetry(originalRequestConfig)) {
                    try {
                        return await axios.request(originalRequestConfig);
                    } catch (error) {
                        console.log({error});
                    }
                }
            }
            return Promise.reject(error);
        }
    );
};

export { interceptor, axios };