import { DataTable, DataTableRowClickEvent } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { DeviceConnectionStatus, DeviceModel } from "../../models/device.model";

import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';

// import { DialogPropTypes } from "../../contexts/types";
// import { DialogContext } from "../../contexts/DialogContext";
// import { DialogTypes, OpenDialogArgs } from "../general/CustomDialog";

interface DeviceListProps {
    devices: DeviceModel[] | undefined;
    isDevicesObjUpdated: boolean | undefined;
}

const _DeviceList = ({ devices, isDevicesObjUpdated }: DeviceListProps) => {
    const { t } = useTranslation();
    const navigate: NavigateFunction = useNavigate();

    // TODO: add a create device dialog.
    // const { showDialog, hide } = React.useContext(
    //     DialogContext
    // ) as DialogPropTypes;

    const navigateToDeviceSetting = (event: DataTableRowClickEvent) => {
        navigate(`/settings/devices/${event.data.id}`);
    };

    const patientNameBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="custom-table__data">
                {rawData?.patient?.first_name
                    ? rawData?.patient?.first_name
                    : "" + " " + rawData?.patient?.last_name
                    ? rawData?.patient?.last_name
                    : ""}
            </div>
        );
    };

    const deviceSerialBodyTemplate = (rawData: DeviceModel) => {
        return <div className="custom-table__data">{rawData.serial}</div>;
    };

    const deviceIdTemplate = (rawData: DeviceModel) => {
        return <div className="custom-table__data">{rawData.id}</div>;
    };

    // TODO: will add in the future.
    // const deviceStatusTemplate = (rawData: DeviceModel) => {
    //     const deviceState = rawData?.shadow?.state?.presence?.state;
    //     return (
    //         <div className="custom-table__data">
    //             {deviceState === DeviceConnectionStatus.CONNECTED
    //                 ? t("Connected")
    //                 : t("Not Connected")}
    //         </div>
    //     );
    // };

    const roomNumberBodyTemplate = (rawData: DeviceModel) => {
        return (
            <div className="custom-table__data">
                {rawData?.patient?.room_number}
            </div>
        );
    };

    const getRowClass = (rawData: DeviceModel) => {
        return {
            "custom-table__table-row": true,
        };
    };

    // TODO: add the detach/attach button
    // const openAddDeviceDialog = () => {
    //     const args: OpenDialogArgs = {
    //         dialogType: DialogTypes.FORM_DIALOG,
    //         title: t("Add new device"),
    //         component: <CreateDeviceForm onClose={() => hide()} />,
    //         actionText: t("Save"),
    //         cancelText: t("Cancel"),
    //     };

    //     showDialog(args);
    // };

    
    const HEIGHT = 3;
    const sceletonItems = [{height: HEIGHT},{height: HEIGHT},{height: HEIGHT},{height: HEIGHT},{height: HEIGHT}];

    const bodyTemplate = (height: any) => {
        return <Skeleton height={`${height.height}rem`} className="overview__data skeleton"></Skeleton>;
    };

    const skeletonTable = () => {
        return (
            <DataTable value={sceletonItems} className="p-datatable-striped">
                <Column
                    header={t("DEVICE SERIAL")}
                    style={{ width: "25%" }}
                    body={bodyTemplate}
                ></Column>
                <Column
                    header={t("MAC ADDRESS")}
                    style={{ width: "25%" }}
                    body={bodyTemplate}
                ></Column>
                <Column
                    header={t("PATIENT NAME")}
                    style={{ width: "25%" }}
                    body={bodyTemplate}
                ></Column>
                <Column
                   header={t("PATIENT ROOM")}
                    style={{ width: "25%" }}
                    body={bodyTemplate}
                ></Column>
            </DataTable>
        );
    };

    return (
        <div className="device-list">
            {isDevicesObjUpdated ? <DataTable
                rowHover={true}
                className="custom-table"
                value={devices}
                rowClassName={getRowClass}
                responsiveLayout="scroll"
                onRowClick={navigateToDeviceSetting}
                emptyMessage={t("No devices found")}
            >
                <Column
                    header={t("DEVICE SERIAL")}
                    body={deviceSerialBodyTemplate}
                ></Column>
                <Column
                    header={t("MAC ADDRESS")}
                    body={deviceIdTemplate}
                ></Column>
                <Column
                    header={t("PATIENT NAME")}
                    body={patientNameBodyTemplate}
                ></Column>
                <Column
                    header={t("PATIENT ROOM")}
                    body={roomNumberBodyTemplate}
                ></Column>
      
            </DataTable> : skeletonTable()}
        </div>
    );
};

export const DeviceList = connect(null, null)(_DeviceList);
