import _ from "lodash";
import { RecordingAction, RecordingEventActionTypes } from "../actions";
import { PreCommunicationSlotModel, RecordingSlotsModel, RecordingsObjModel } from "../models/recording.model";
import { getSlotByNumber, sortFamilyRecordings } from "../utils";

const initialState: Map<string, RecordingsObjModel> = new Map();

export function recordingReducer(
    state: Map<string, RecordingsObjModel> = initialState,
    action: RecordingAction
): Map<string, RecordingsObjModel> {
    switch (action.type) {
        case RecordingEventActionTypes.FAMILY_RECORDINGS_PENDING:
            return updateRecordingsPendingState({
                state,
                patientId: action.payload.patientId,
                pendingType: "familyRecordingPending",
            });

        case RecordingEventActionTypes.SLOTS_PENDING:
            return updateRecordingsPendingState({
                state,
                patientId: action.payload.patientId,
                pendingType: "slotsPending",
            });

        case RecordingEventActionTypes.GENERAL_RECORDINGS_PENDING:
            return updateRecordingsPendingState({
                state,
                patientId: action.payload.patientId,
                pendingType: "generalRecordingPending",
            });

        case RecordingEventActionTypes.FAMILY_RECORDINGS_ERROR:
            return updateRecordingsStateOnError({
                state,
                patientId: action.payload.patientId,
                pendingType: "familyRecordingPending",
                recordingType: "familyRecordings"
            });
        case RecordingEventActionTypes.SLOTS_ERROR:
            return updateRecordingsStateOnError({
                state,
                patientId: action.payload.patientId,
                pendingType: "slotsPending",
                recordingType: "slots"
            });
        case RecordingEventActionTypes.GENERAL_RECORDINGS_ERROR:
            return updateRecordingsStateOnError({
                state,
                patientId: action.payload.patientId,
                pendingType: "generalRecordingPending",
                recordingType: "generalRecordings"
            });

        case RecordingEventActionTypes.FETCH_FAMILY_RECORDINGS: {
            let { patientId, familyRecordings } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (!recordings) {
                recordings = {} as RecordingsObjModel;
            }

            const updatedRecordings = {
                ...recordings,
                familyRecordingPending: false,
                familyRecordings: [
                    ...familyRecordings.sort(sortFamilyRecordings),
                ],
            };
            newMap.set(patientId, updatedRecordings);

            return newMap;
        }

        case RecordingEventActionTypes.FETCH_GENERAL_RECORDINGS: {
            let { patientId, generalRecordings } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (!recordings) {
                recordings = {} as RecordingsObjModel;
            }

            const updatedRecordings = {
                ...recordings,
                generalRecordingPending: false,
                generalRecordings: [...generalRecordings],
            };

            newMap.set(patientId, updatedRecordings);

            return newMap;
        }

        case RecordingEventActionTypes.FETCH_RECORDING_SLOTS: {
            let { patientId, slots } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            // TODO: to add a generic number of slots.
            const NUM_OF_SLOTS = 5;
            let initialSlots: Array<RecordingSlotsModel> = [];

            for (let slotIndex = 1; slotIndex <= NUM_OF_SLOTS; slotIndex++) {
                const currentSlot = getSlotByNumber(slotIndex, slots);

                if (currentSlot) {
                    initialSlots.push(currentSlot);
                } else {
                    initialSlots.push({
                        creation_date: 0,
                        last_update: 0,
                        number: slotIndex,
                        patient_id: patientId,
                        recording_id: "",
                    });
                }
            }

            if (!recordings) {
                recordings = {} as RecordingsObjModel;
            }

            const updatedRecordings = {
                ...recordings,
                slotsPending: false,
                slots: [...initialSlots],
            };

            newMap.set(patientId, updatedRecordings);

            return newMap;
        }

        case RecordingEventActionTypes.FETCH_PRE_COMMUNICATION_SLOT: {
            let { patientId, preCommunicationSlot } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (!recordings) {
                recordings = {} as RecordingsObjModel;
            }

            const updatedRecordings: RecordingsObjModel = {
                ...recordings,
                preCommunicationSlot,
                slotsPending: false
            };

            newMap.set(patientId, updatedRecordings);

            return newMap;
        }

        case RecordingEventActionTypes.UPDATE_PRE_COMMUNICATION_SLOT: {
            let { patientId, slotName, recordingId } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);
            
            if (!recordings?.preCommunicationSlot) {
                recordings = {
                  ...recordings,
                  preCommunicationSlot: recordings?.preCommunicationSlot || {} as PreCommunicationSlotModel,
                } as RecordingsObjModel;
              }

            (recordings as RecordingsObjModel).preCommunicationSlot.name = slotName;
            (recordings as RecordingsObjModel).preCommunicationSlot.recording_id = recordingId;

            const updatedRecordings = {
                ...recordings
            };

            newMap.set(patientId, updatedRecordings as RecordingsObjModel);
            

            return newMap;
        }

        case RecordingEventActionTypes.DELETE_PRE_COMMUNICATION_SLOT: {
            let { patientId } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (recordings?.preCommunicationSlot) {
                recordings.preCommunicationSlot.name = "Stimuli";
                recordings.preCommunicationSlot.recording_id = "";

                const updatedRecordings = {
                    ...recordings
                };

                newMap.set(patientId, updatedRecordings);
            }

            return newMap;
        }

        case RecordingEventActionTypes.SORT_FAMILY_RECORDINGS: {
            let { patientId } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (recordings?.familyRecordings) {
                const updatedRecordings = {
                    ...recordings,
                    familyRecordings: [
                        ...recordings.familyRecordings.sort(
                            sortFamilyRecordings
                        ),
                    ],
                };
                newMap.set(patientId, updatedRecordings);
            }

            return newMap;
        }

        case RecordingEventActionTypes.UPDATE_FAMILY_RECORDING: {
            let { patientId, updatedRecording } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (recordings?.familyRecordings) {
                const updatedFamilyRecordings = recordings.familyRecordings.map(
                    (familyRecording) => {
                        if (familyRecording.id === updatedRecording.id) {
                            return _.assign(familyRecording, updatedRecording);
                        }

                        return familyRecording;
                    }
                );

                const updatedRecordings = {
                    ...recordings,
                    familyRecordings: [...updatedFamilyRecordings],
                };

                newMap.set(patientId, updatedRecordings);
            }

            return newMap;
        }

        case RecordingEventActionTypes.UPDATE_RECORDING_SLOT: {
            let { patientId, slotNumber, recordingId } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (recordings?.slots) {
                const updatedSlots = recordings.slots.map((slot) => {
                    if (slot.number === slotNumber) {
                        slot.recording_id = recordingId;
                    }

                    return slot;
                });

                const updatedRecordings = {
                    ...recordings,
                    slot: [...updatedSlots],
                };

                newMap.set(patientId, updatedRecordings);
            }

            return newMap;
        }

        case RecordingEventActionTypes.DELETE_RECORDING_SLOT: {
            let { patientId, slotNumber } = action.payload;
            const newMap = new Map(state);
            let recordings = newMap.get(patientId);

            if (recordings?.slots) {
                const updatedSlots = recordings.slots.map((slot) => {
                    if (slot.number === slotNumber) {
                        slot.recording_id = "";
                    }

                    return slot;
                });

                const updatedRecordings = {
                    ...recordings,
                    slot: [...updatedSlots],
                };

                newMap.set(patientId, updatedRecordings);
            }

            return newMap;
        }
        default:
            return state;
    }
}

interface UpdateRecordingsParams {
    state: Map<string, RecordingsObjModel>;
    patientId: string;
    pendingType: string;
}

interface ErrorRecordingsParams {
    state: Map<string, RecordingsObjModel>;
    patientId: string;
    pendingType: string;
    recordingType: string;
}

const updateRecordingsPendingState = ({
    state,
    patientId,
    pendingType,
}: UpdateRecordingsParams): Map<string, RecordingsObjModel> => {
    const newMap = new Map(state);
    let recordings = newMap.get(patientId);

    if (!recordings) {
        recordings = {} as RecordingsObjModel;
    }

    const updatedRecordings = {
        ...recordings,
        [pendingType]: true,
    };

    newMap.set(patientId, updatedRecordings);

    return newMap;
};

const updateRecordingsStateOnError = ({
    state,
    patientId,
    pendingType,
    recordingType
}: ErrorRecordingsParams): Map<string, RecordingsObjModel> => {
    const newMap = new Map(state);
    let recordings = newMap.get(patientId);

    if (!recordings) {
        recordings = {} as RecordingsObjModel;
    }

    const updatedRecordings = {
        ...recordings,
        [pendingType]: false,
        [recordingType]: []
    };

    newMap.set(patientId, updatedRecordings);

    return newMap;
};
