import { Amplify, PubSub, Auth , Hub, Logger, AWSCloudWatchProvider, Storage} from 'aws-amplify';
import { AWSIoTProvider, CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import { NavigateFunction } from 'react-router-dom';
import { logoutUser } from '../actions';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../reducers';

class AmplifyService
{
    private static _instance: AmplifyService;
    private static portalLogger: Logger;

    private constructor()
    {
        const timestamp = Date.now();

        Amplify.configure({
            Auth: {
              identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
              region: process.env.REACT_APP_REGION,
              userPoolId: process.env.REACT_APP_USER_POOL_ID,
              userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
              oauth: {
                domain: `${process.env.REACT_APP_SUB_DOMAIN}.auth.${process.env.REACT_APP_REGION}.amazoncognito.com`,
                scope:  ["email", "profile", "openid"],
                redirectSignIn: `${process.env.REACT_APP_OVERVIEW_PAGE}`,
                redirectSignOut: `${process.env.REACT_APP_LOGIN_PAGE}`,
                responseType: "code"
              }
            },
            Logging: {
                logGroupName: 'portal',
                logStreamName: `portal-${timestamp}`,
                region: process.env.REACT_APP_REGION
            },
            Storage: {
                AWSS3: {
                  bucket: process.env.REACT_APP_MED_STORAGE_BUCKET,
                  region: process.env.REACT_APP_REGION
                }
            }
        });

        Amplify.addPluggable(new AWSIoTProvider({
            aws_pubsub_region: process.env.REACT_APP_REGION,
            aws_pubsub_endpoint: `wss://${process.env.REACT_APP_MQTT_ID}.iot.${process.env.REACT_APP_REGION}.amazonaws.com/mqtt`,
        }));
        
        AmplifyService.portalLogger = new Logger('portalLogger', 'DEBUG');
        Amplify.register(AmplifyService.portalLogger);
        AmplifyService.portalLogger.addPluggable(new AWSCloudWatchProvider());

        Amplify.Logger.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
        // Amplify.Logger.LOG_LEVEL = "DEBUG"
    }

    public log(log:any) {
        return AmplifyService.portalLogger.verbose(JSON.stringify(log));
    }

    public get getAmplify() {
        return Amplify;
    }

    public get getPubSub() {
        return PubSub;
    }

    public get getStorage() {
        return Storage;
    }

    public get getAuth() {
        return Auth;
    }

    public get getHub() {
        return Hub;
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public connectivityCheck = (navigate: NavigateFunction, dispatch: ThunkDispatch<StoreState, void, Action>) => {
        Hub.listen('pubsub', (data: any) => {
            const { payload } = data;
            if (payload.event === CONNECTION_STATE_CHANGE) {
              const connectionState = payload.data.connectionState as ConnectionState;
              this.log({date: new Date() , connectionState});
            }
        });

        Hub.listen('auth', async (data) => {
            switch (data.payload.event) {
                case 'tokenRefresh': {
                    this.log("New token has been process");
                    break;
                }
                case 'tokenRefresh_failure': {
                    this.log("Failed to retrieve new token. navigate to login page");
                    dispatch(logoutUser(() => {
                        navigate(`/login`);
                    }))
                    break;
                }
            }
        });
    }
}
 
export default AmplifyService.Instance;