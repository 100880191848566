import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "moment-timezone";
import { ActiveCommunicationType, DeviceModel } from "../../models/device.model";
import { CommunicationIcon, WrapSize } from "../../icons";
import { StoreState } from "../../utils";
import TimeService from "../../services/time.service";
import { connect } from "react-redux";
import { NOTIFICATIONS, NotificationModel, Styles } from "../../models/notification.model";
import Moment from "react-moment";

interface ResponsesProps {
    devices?: DeviceModel[];
    timeZone: string | undefined;
    deviceId: string | undefined;
}

const mapStateToProps = ({ deviceObject }: StoreState) => {
    return {
        devices: deviceObject.devices
    };
};

export const _Responses = ({
    devices, timeZone, deviceId
}: ResponsesProps) => {
    const { t } = useTranslation();
    const [currentDevice, setCurrentDevice] = useState<DeviceModel>();
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const [twoDaysFilter, setTwoDaysFilter] = useState<Boolean>(true)
    const [isExistOldResults, setIsExistOldResults] = useState<Boolean>(false);

    const findCurrentDevice = () => devices?.find((device) =>  device.id === deviceId);

    const filterNotifications = (): NotificationModel[] => {
        if (!currentDevice || !currentDevice.notifications) {
            return [];
        }
    
        return currentDevice.notifications.filter(notification => {
            const isQAResults = notification.configurations.notification === NOTIFICATIONS.Q_AND_A_RESULTS;
    
            if (!isQAResults) {
                return false;
            }
    
            if (!timeZone || !twoDaysFilter) {
                return true;
            }
    
            const isRecent = TimeService.isWithinLastTwoDays(notification.timestamp, timeZone);
    
            if (!isRecent) {
                setIsExistOldResults(true);
            }
    
            return isRecent;
        });
    };
        

    const getNotificationElement = (text: string | undefined, styles: Styles | undefined): JSX.Element => {
        if (!text) {
            return <span />;
        }
    
        if (!styles) {
            return <span>{text}</span>;
        }
        
        const applyStyles = (text: string): JSX.Element => {
            const elements: JSX.Element[] = [];
            let currentIndex = 0;
    
            const addTextPart = (part: string, isBold: boolean, isItalic: boolean) => {
                elements.push(
                    <span
                        key={currentIndex}
                        style={{
                            fontWeight: isBold ? 'bold' : 'normal',
                            fontStyle: isItalic ? 'italic' : 'normal'
                        }}
                    >
                        {part}
                    </span>
                );
                currentIndex++;
            };
    
            const { bold = [], italic = [] } = styles;
    
            let pattern = bold.concat(italic).join('|');
            const regex = new RegExp(`(${pattern})`, 'g');
    
            let match;
            let lastIndex = 0;
    
            while ((match = regex.exec(text)) !== null && regex.lastIndex > 0) {
                const startPart = text.slice(lastIndex, match.index);
                if (startPart) {
                    addTextPart(startPart, false, false);
                }
    
                const matchedText = match[0];
                const isBold = bold.includes(matchedText);
                const isItalic = italic.includes(matchedText);
    
                addTextPart(matchedText, isBold, isItalic);
                lastIndex = regex.lastIndex;
            }
    
            const remainingPart = text.slice(lastIndex);
            if (remainingPart) {
                addTextPart(remainingPart, false, false);
            }
    
            return <>{elements}</>;
        };
    
        return <span>{applyStyles(text)}</span>;
    }
    
    useEffect(() => {
        let device = findCurrentDevice();
        if(device) {
            setCurrentDevice(device);
            const notifications = filterNotifications();
            setNotifications(notifications);
        }
    }, [deviceId]);

    useEffect(() => {
        if (currentDevice && currentDevice.notifications) {
            const notifications = filterNotifications();
            setNotifications(notifications)
        }
    }, [currentDevice?.notifications.length, twoDaysFilter]);

    if (notifications.length > 0) {
        return (
            <div className="near-bed-view-page__dialog">
                <ul className="near-bed-view-page__items near-bed-view-page__items__responses">
                    {
                        notifications.map(
                            (notification: NotificationModel, index: number) => {
                                return (
                                    <li
                                        key={index}
                                        className="near-bed-view-page__qna-item"
                                    >
                                        <div className="near-bed-view-page__icon">
                                            <CommunicationIcon
                                                width="4rem"
                                                height="4rem"
                                                communicatoinType={
                                                    ActiveCommunicationType.Q_AND_A
                                                }
                                                wrapSize={WrapSize.MEDIUM}
                                            />
                                        </div>
                                        <div
                                            className={`near-bed-view-page__line near-bed-view-page__line--${ActiveCommunicationType.Q_AND_A}`}
                                        ></div>
                                        <div
                                            className={`near-bed-view-page__information-wrapper ${
                                                notification.configurations.isRead ? "" : "near-bed-view-page__responses-unread"
                                            }`}
                                        >
                                                <div className="near-bed-view-page__text">
                                                    {getNotificationElement(notification.configurations.text, notification.configurations.styles)}
                                                </div>
                                                <div className="near-bed-view-page__date">
                                                        <Moment tz={timeZone} format="MMM Do, YYYY">{notification.timestamp}</Moment>
                                                    <div className="near-bed-view-page__hour">
                                                        <Moment tz={timeZone} format="HH:mm">{notification.timestamp}</Moment>
                                                    </div>
                                                </div>
                                        </div>
                                    </li>
                                );
                            }
                        )
                    }
                </ul>
                {
                    twoDaysFilter && isExistOldResults ?
                        <div className="near-bed-view-page__all-responses" onClick={() => {setTwoDaysFilter(false)}}>
                            {t("Show all responses")}
                        </div>
                    : <></>
                }
            </div>
        )  

    }
    return (
        <div className=" responses-content__dialog">
            <ul className="near-bed-view-page__items near-bed-view-page__items__responses">
                    <div className="responses-content__no-responses">
                        {t("No new patient responses have been collected in the last 48 hours")}
                    </div>
            </ul>
            {
                twoDaysFilter && isExistOldResults ?
                    <div className="near-bed-view-page__all-responses" onClick={() => {setTwoDaysFilter(false)}}>
                        {t("Show all responses")}
                    </div>
                : <></>
            }
        </div>
    )                       
};

export const Responses = connect(
    mapStateToProps,
    null,
  )(_Responses);