import {
    UserModel,
    UserLogin,
    UserUpdatePassword,
} from "../models/user.model";
import { AuthenticationActionTypes, DeviceActionTypes} from "./types";
import { Action, Dispatch } from "redux";
import authApi from "../apis/auth";
import userApi from "../apis/user";
import roleApi from "../apis/role";
import AmplifyService from "../services/amplifyService";
import { ThunkDispatch } from "redux-thunk";
import { StoreState } from "src/reducers";
import { getIcus } from "./icu.action";
import { DeleteDevicesAction } from "./device.action";
import { decodeJwt } from '../utils';
import { NavigateFunction } from 'react-router-dom';
import { AuthorizationModel } from "../models/Authorization.model";

// const REFRESH_TOKEN_INTERVAL_MINUTES = 7;
// const MILLISECONDS_IN_MINUTE = 60000;

export interface AuthenticatedAction {
    type: AuthenticationActionTypes.AUTHENTICATED;
    payload: {
        user: UserModel,
        role: AuthorizationModel
    };
}

export interface NotAuthenticatedAction {
    type: AuthenticationActionTypes.NOT_AUTHENTICATED;
    payload: string | unknown;
}

export type AuthenticationAction = AuthenticatedAction | NotAuthenticatedAction;

export const setToken = (token: string) => {
    localStorage.setItem("token", token);
};

export const setUser = (user: UserModel) => {
    localStorage.setItem("user", JSON.stringify(user));
};

export const setRole = (role: UserModel) => {
    localStorage.setItem("role", JSON.stringify(role));
};

export const getUser = () => {
    const user = localStorage.getItem("user") ;
    return user ? user : "";
};

export const getToken = () => {
    if(process.env.REACT_APP_IS_SAML?.toLowerCase() == 'true') {
        const token = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}.${getSamlUserName()}.idToken`);
        return token ? token : "";
    }
    const token = localStorage.getItem("token");
    
    return token ? token : "";
};

export const getSessionId = () => {
    const sessionId = localStorage.getItem("sessionId") ;
    return sessionId || "";
};

export const setSessionId = (sessionId: string) => {
    localStorage.setItem("sessionId", sessionId) ;
};

export const getSamlUserName = () => {
    const userName = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`);
    return userName ? userName : "";
};

const clearLocalStorage = () => {
    AmplifyService.log("clearing local storage");
    localStorage.clear();
}

export const updateUserToken = (dispatch: Dispatch, token: string, role: AuthorizationModel) => {
    const userStr: string = getUser();
    const user: UserModel = JSON.parse(userStr);
    user.token = token;
    
    dispatch<AuthenticatedAction>({
        type: AuthenticationActionTypes.AUTHENTICATED,
        payload: {user, role: role},
    });
}

export const refreshToken = async ( ) => {
    let Auth = AmplifyService.getAuth;
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken
    
    setToken(token);
    return token;
}

export const loginUser = (credentials: UserLogin, callBack: Function, provider: string) => {
    return async (dispatch: ThunkDispatch<StoreState, void, Action>) => {
        if(process.env.REACT_APP_IS_SAML?.toLowerCase() == 'true'){
            await samlAuthentication(provider);
        }
        //No need for else clause since samlAuthentication redirecting
        try {
            let Auth = AmplifyService.getAuth;
            AmplifyService.log(`login user ${credentials.userName}...`)
            let user = await Auth.signIn(credentials.userName, credentials.password);
            const sessionId = `${credentials.userName}-${Date.now()}`
            
            if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
                const info = await Auth.currentUserCredentials();
                const {identityId} = info;
                AmplifyService.log(`identityId ${identityId}`)
                
                const tenantId = "tenantId";
                AmplifyService.log(`User ${credentials.userName} logged in for the first time. Confirming password...`)
                await userApi.post("confirm", {...credentials, identityId, tenantId});
                AmplifyService.log(`User ${credentials.userName} password confirmed. Signing in...`)
                user = await Auth.signIn(credentials.userName, credentials.password);
            }
            AmplifyService.log(`User ${credentials.userName} logged in`);

            if (user && user.signInUserSession && user.signInUserSession.idToken) {
                const idToken = user.signInUserSession?.idToken;
                const userToken = idToken?.jwtToken;

                const currentUser: UserModel = {
                    userName: idToken.payload["cognito:username"],
                    email: idToken.payload["email"],
                    role: idToken.payload["cognito:roles"],
                    groups: idToken.payload["custom:Groups"],
                    permissions: [],
                    token: userToken,
                };


                const role =  await roleApi.get("", {headers: {Authorization: `Bearer ${userToken}`}});

                if (!role.data) {
                    throw new Error("No Role found");
                }

                dispatch<AuthenticatedAction>({
                    type: AuthenticationActionTypes.AUTHENTICATED,
                    payload: {
                        user: currentUser,
                        role: role.data
                    },
                });

                dispatch(getIcus(userToken));

                setUser(currentUser);
                setToken(userToken);
                setSessionId(sessionId);

                if (role.data) {
                    setRole(role.data);
                }

                callBack("/overview");
            } else {
                if (user.status === 403 && user.error === "Please update the password") {
                    callBack("updatePassword");
                }
            }

        } catch (error: any) {
            const err = {
                code: error.code,
                name: error.name,
                message: error.message
            }
            callBack(err);
            dispatch<NotAuthenticatedAction>({
                type: AuthenticationActionTypes.NOT_AUTHENTICATED,
                payload: error,
            });
        }
    };
};

export const samlAuthentication = async (provider: string) => {
    let Auth = AmplifyService.getAuth;
    return Auth.federatedSignIn({ customProvider: provider });
}

export const getSamlUser = async (dispatch: ThunkDispatch<StoreState, void, Action>, navigate: NavigateFunction) => {
   try {
    const token: string = getToken();

    if (token) {
        const {decodedPayload} = decodeJwt(token);
        let Auth = AmplifyService.getAuth;
        const info = await Auth.currentUserCredentials();
        const {identityId} = info;

        AmplifyService.log(`identityId from getSamlUser: ${identityId}`)
        
        await userApi.post("attach-iot-policy",{identityId, userName: decodedPayload["cognito:username"]});
        
        const emailMatch = decodedPayload["cognito:username"].match(/[^_]+_([^@]+@[^.]+\..+)/);

        const currentUser: UserModel = {
            userName: decodedPayload["cognito:username"],
            email: emailMatch ? emailMatch[1] : "",
            role: "",
            groups: decodedPayload["custom:Groups"],
            permissions: [],
            token: token,
        };
        
        AmplifyService.log(` user ${currentUser.userName} logged in`);

        const role =  await roleApi.get("", {headers: {Authorization: `Bearer ${token}`}});

        if (!role.data) {
            throw new Error("No Role found");
        }

        dispatch<AuthenticatedAction>({
            type: AuthenticationActionTypes.AUTHENTICATED,
            payload: { user: currentUser, role: role.data },
        });

        dispatch(getIcus(token));
        setUser(currentUser);
        const sessionId = `${currentUser.userName}-${Date.now()}`
        setSessionId(sessionId)

        return {
            currentUser,
            token
        }
    }
   }
   catch (error) {
    AmplifyService.log(`getSamlUser error: ${error}`);
   }
};

export const updatePassword = (credentials: UserUpdatePassword, callBack: Function) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await authApi.post("updatePassword", credentials);

            if (response.status === 201) {
                dispatch<NotAuthenticatedAction>({
                    type: AuthenticationActionTypes.NOT_AUTHENTICATED,
                    payload: "",
                });
                callBack("login");
            }
            else {
                let data = response.data;
                dispatch<NotAuthenticatedAction>({
                    type: AuthenticationActionTypes.NOT_AUTHENTICATED,
                    payload: "updatePassword",
                });
                if (data.status === 403 && data.error === "Please update the password") {
                    callBack("updatePassword");
                }
            }
        } catch (error) {
            console.log(error);
            dispatch<NotAuthenticatedAction>({
                type: AuthenticationActionTypes.NOT_AUTHENTICATED,
                payload: error,
            });
        }
    };
};

export const logoutUser = (callBack: Function) => {
    return async (dispatch: Dispatch) => {
        let Auth = AmplifyService.getAuth;
        try {
            setTimeout(async () => {
                await Auth.signOut();
            });
            clearLocalStorage();
            dispatch<NotAuthenticatedAction>({
                type: AuthenticationActionTypes.NOT_AUTHENTICATED,
                payload: "",
            });

            dispatch<DeleteDevicesAction>({
                type: DeviceActionTypes.DELETE_DEVICES
            });

            callBack("login");
        } catch (error) {
            console.log(error);
        }
    };
};