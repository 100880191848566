import React from "react";

export const LockIcon = ({
    height = "24",
    width = "24",
    ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6" clipPath="url(#clip0_5165_29396)">
        <rect x="3.5" y="11.5" width="18" height="13" rx="2" fill="#0F284C"/>
        <path d="M16.8467 5.33531C16.5641 4.28078 15.9078 3.36492 15.0001 2.75839C14.0923 2.15185 12.995 1.896 11.9126 2.0385C10.8302 2.181 9.83655 2.71213 9.11672 3.53294C8.39689 4.35376 8 5.40826 8 6.5" stroke="#0F284C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="6.5" y="6.5" width="3" height="6" fill="#0F284C"/>
    </g>
    <defs>
        <clipPath id="clip0_5165_29396">
            <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
    </defs>
    </svg>
);
