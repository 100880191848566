import moment from "moment/moment";
import "moment-timezone";
import { REPEAT_TYPE, SchedulerEvent, WEEK_DAYS } from "src/models/scheduler.model";
import {timeToMinutes} from "src/utils";
import {ActiveCommunicationType} from "src/models/device.model";

export const getTodayEvents = (
    events: SchedulerEvent[],
    timeZone: string | undefined
): SchedulerEvent[] => {
    const today = moment().tz(timeZone || "");

    if (!today) {
        return [];
    }

    return events
        .filter((event) => {
            const {startDate, timezone, repeat: {type, days, isActive}} = event
            const eventStartDate = moment.tz(startDate, timezone);

            if (isActive) {
                return (type === REPEAT_TYPE.DAILY || (days.includes(today.format("dddd") as WEEK_DAYS)
                    && (eventStartDate.isBefore(today, "day")
                        || eventStartDate.isSame(today, "day"))));
            }

            return eventStartDate.isSame(today, "day");
        })
        .sort((a, b) => timeToMinutes(a.time) - timeToMinutes(b.time));
}

interface GetEventDurationProps {
    communicationTypeId: ActiveCommunicationType | undefined
    musicDuration?: string | undefined
    familyDuration?: string | undefined
}

export const getEventDuration = (
    {communicationTypeId, musicDuration, familyDuration}: GetEventDurationProps): string => {
    switch (communicationTypeId) {
        case ActiveCommunicationType.TRAINING:
        case ActiveCommunicationType.Q_AND_A:
            return '4 min'
        case ActiveCommunicationType.CAM_ICU:
        case ActiveCommunicationType.GENERAL_MESSAGE:
            return '4-7 min'
        case ActiveCommunicationType.ORIENTATION:
        case ActiveCommunicationType.INSTRUCTIONS:
            return '1 min'
        case ActiveCommunicationType.MUSIC:
        case ActiveCommunicationType.PERSONAL_MUSIC:
            if (musicDuration) return musicDuration

            return ''
        case ActiveCommunicationType.FAMILY:
            if (familyDuration) return familyDuration

            return ''
        default:
            return ''
    }
}

export const getRoundedMinutesFromMilliseconds = (milliseconds?: number): number => {
    if (!milliseconds) return 0

    return Math.ceil((milliseconds / 1000 / 60) % 60);
}

const getRoundedMinutesFromSeconds = (minutes: number): number => {
    if (!minutes) return 0

    return Math.ceil((minutes / 60) % 60);
}

export const getFamilyRecordingsDuration = (familyRecordingsDuration = 0, familyRecordingsTolerance = 0): string => {
    const fromMinutes = getRoundedMinutesFromSeconds(familyRecordingsDuration - familyRecordingsTolerance)
    const toMinutes = getRoundedMinutesFromSeconds(familyRecordingsDuration + familyRecordingsTolerance)

    return `${fromMinutes}-${toMinutes} min`
}