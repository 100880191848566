import { AuthorizationModel } from '../models/Authorization.model';
import { AuthenticationAction, AuthenticationActionTypes } from '../actions';
import { AuthenticationModel } from '../models/user.model';

export const getToken = () => {
    return localStorage.getItem("token");
};

export const getRole = () => {
    const role = localStorage.getItem("role");

    return role ? JSON.parse(role) : null;
};

export const getCurrentUser = () => {
    const user = localStorage.getItem("user");

    return user ? JSON.parse(user) : null;
};

const initialState: AuthenticationModel = {
    isAuthChecked: getToken() ? true : false,
    isAuthorized: getRole() ? true : false,
    isLoggedIn: getToken() ? true : false,
    currentUser: getCurrentUser(),
    errorMessage: "",
    authorization: getToken() && getRole() ? getRole() : null
};

export function authenticationReducer(state: AuthenticationModel = initialState, action: AuthenticationAction) {
    switch (action.type) {
        case AuthenticationActionTypes.AUTHENTICATED:
            const {user, role} = action.payload;
            return {
                ...state,
                isAuthChecked: true,
                isLoggedIn: true,
                currentUser: user,
                errorMessage: "",
                authorization: role,
                isAuthorized: true
            };
        case AuthenticationActionTypes.NOT_AUTHENTICATED:
            let errorMessage = "";
            if (typeof action.payload === "string" && action.payload) {
                errorMessage = action.payload;
            }
            return {
                ...state,
                isAuthChecked: true,
                isLoggedIn: false,
                currentUser: null,
                errorMessage: errorMessage,
                authorization: null,
                isAuthorized: false
            };
        default:
            return state;
    }
}
