import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "moment-timezone";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { MessageType } from "src/models/log.model";
import {
    ActiveCommunicationType, CommunicationLogModel, DeviceModel, getCommunicationSecLength,
    mapCommunicationTypeToStringPlayButtons,
} from "src/models/device.model";
import { CommunicationIcon, StopPlayerIcon, WrapSize } from "src/icons";
import { stopCommunication } from "src/utils";
import { showDialogType } from "src/contexts/types";
import { SenderSource } from "src/constans/senderSourceEnum";
import TimeService from "src/services/time.service";
import { RecordingsObjModel } from "../../models/recording.model";
import { useMixpanel } from "src/hooks/useMixpanel";

interface CurrentCommunicationViewProps {
    deviceId: string | undefined;
    activeCommunicationId: ActiveCommunicationType;
    subTypeId?: number;
    showDialog: showDialogType;
    currentDevice: DeviceModel;
    // recordings: Map<string, RecordingsObjModel> | undefined
}

export const CurrentCommunicationView = ({
    deviceId,
    activeCommunicationId,
    subTypeId,
    showDialog,
    currentDevice,
    // recordings
}: CurrentCommunicationViewProps) => {
    const { t } = useTranslation();
    const [stoppingComminucation , setStoppingComminucation] = useState(false);
    const [currentCommunication, setCurrentCommunication] = useState<
        CommunicationLogModel | undefined
    >();
    const [playProgress, setPlayProgress] = useState<number>(0);
    const [playSeconds, setPlaySeconds] = useState<number>(0);
    const [communicationLength, setCommunicationLength] = useState<number>(0);
    const interval = useRef<NodeJS.Timer>();
    const {
        trackCloseStopModalUsingCloseButton, trackStopCommunicationModalClickStop, trackOpenStopCommunicationModal
    } = useMixpanel()

    useEffect(() => {
        setCommunicationLength(getCommunicationSecLength(activeCommunicationId, currentDevice.patient?._id));
        // setCommunicationLength(getCommunicationSecLength(activeCommunicationId, recordings, currentDevice.patient?._id));

        const lastRecievedTime = findLastRecieved(currentDevice?.communicationLogs);
        if (lastRecievedTime) {
            let lastRecievedTimestamp = typeof lastRecievedTime === "string" ? 
                new Date(lastRecievedTime).getTime()//Date.parse(lastRecievedTime) 
                : parseInt(lastRecievedTime);
                
            const currentSeconds = (new Date().getTime() - lastRecievedTimestamp) / 1000 | 0;
            setPlaySeconds(currentSeconds);
            setPlayProgress(currentSeconds / communicationLength * 100);
        }
    }, []);
    
    useEffect(() => {

        interval.current = setInterval(() => {
            setPlaySeconds(playSeconds + 1)
            setPlayProgress(playSeconds / communicationLength * 100);
        }, 1000);
    
        return () => clearInterval(interval.current);
    }, [playProgress]);
     

    useEffect(() => {
        if(currentDevice && currentDevice.communicationLogs) {
            let lastLog = currentDevice?.communicationLogs[currentDevice?.communicationLogs.length - 1]
            if(currentDevice?.communicationLogs[currentDevice?.communicationLogs.length - 1].time < 
                currentDevice?.communicationLogs[0].time
            ) {
                lastLog = currentDevice?.communicationLogs[0];
            }
            const currentLogText = lastLog.text 
            if(isStopped(currentLogText)) {
                setStoppingComminucation(true)
            // } else {
                
                
            //     if (activeCommunicationId ==  ActiveCommunicationType.GENERAL_MESSAGE ||
            //         activeCommunicationId ==  ActiveCommunicationType.FAMILY 
            //     ) {
            //         console.log(lastLog.sub_type_info);
                    
            //     }
            }
        }
    }, [currentDevice?.communicationLogs]);
    
    const isStopped = (log: string) => {
        return log.includes("was stopped by");
    }
    const findLastRecieved = (logs: CommunicationLogModel[] | undefined) => {
        
        if (logs) {
            let i = 0;
            let start = true;
            if(new Date(logs[logs.length - 1].time).getTime() > 
                new Date(logs[0].time).getTime()
            ) {
                i = logs.length - 1;
                start = false;
            }
            for (;start ?  i < logs.length - 1: i >=0;
                start ? i++ : i--
            ) {
                if (logs[i].message_type_id === MessageType.RECIVED_COMMUNICATION) {
                    return logs[i].time;
                }
            }
        }
        return null;
    }

    const sendStopCommunication = (deviceId: string | undefined) => {
        trackOpenStopCommunicationModal();
        if (!stoppingComminucation && deviceId) {
            setStoppingComminucation(true);
            stopCommunication({
                deviceId, showDialog, t, source: SenderSource.TABLET,
                cancelCB: () => {
                    trackCloseStopModalUsingCloseButton()
                    setStoppingComminucation(false)
                },
                actionCB: trackStopCommunicationModalClickStop
            });
        }
    }

    return (
        <div className="current-communication-view">
            <div className="current-communication-view__title-wrapper">
                <div className="current-communication-view__title">
                    {t("Playing Now")}
                </div>
                <div className="current-communication-view__line"/>
                <div className="current-communication-view__communication-name">
                    {mapCommunicationTypeToStringPlayButtons(activeCommunicationId, currentDevice.shadow.state.active_communication_info?.sub_type_info?.questionName)} {subTypeId ? subTypeId : null}
                </div>
            </div>
                <div className="current-communication-view__playing-wrapper">
                    <div className="current-communication-view__playing-animation">
                        <div className="current-communication-view__playing-gif"/>
                        <div className="current-communication-view__big-icon">
                            <CommunicationIcon
                                width="8rem"
                                height="8rem"
                                wrapSize={WrapSize.MID_LARGE}
                                communicatoinType={activeCommunicationId}
                            />
                        </div>
                    </div>
                </div>
                <div className="current-communication-view__progress-bar-container">
                    <div className="current-communication-view__progress-bar-background">
                        <div className="current-communication-view__progress-bar"
                            style={{width : `${playProgress}%`}}>
                        </div>
                    </div>
                    <div className="current-communication-view__progress-bar-time">
                        <div className="current-communication-view__progress-bar-start">
                            {TimeService.formatTime(playSeconds)}
                        </div>
                        <div className="current-communication-view__progress-bar-end">
                            {TimeService.formatTime(communicationLength)}
                        </div>
                    </div>
                </div>
            <div className="current-communication-view__stop-button">
                <Button
                    onClick={() => sendStopCommunication(deviceId)}
                    className={`button button-regular ${stoppingComminucation ? "button-disabled-stop-tablet" : ""}`}
                >
                    {
                        stoppingComminucation ? 
                            <div className="button-loading__near-bed-stop-communication">
                                <ProgressSpinner />
                            </div>
                        :
                            <div className="current-communication-view__stop-button-content">
                                <StopPlayerIcon height="30px" width="30px"/>
                                <div className="current-communication-view__stop-button-text">
                                    {t("Stop")}
                                </div>
                            </div>
                    }
                </Button>
            </div>
        </div>
    );
};