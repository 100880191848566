import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ZenObservable } from "zen-observable-ts";
import {
    DeviceModel
} from "../../models/device.model";
import { VideoStreamBox } from "../patientView/VideoStreamBox";
import { PatientSideBar } from "../patientView/PatientSideBar";
import { NavigateFunction, Outlet, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "src/reducers";
import { PetientActionMenu } from "../patientView/PatientActionMenu";
import { CameraIcon } from "../../icons";
import { filterDevicesWithPatient } from "../../utils";
import { Skeleton } from 'primereact/skeleton';
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { fetchFamilyRecordings, fetchGeneralRecordings, fetchRecordingSlots, fetchPreCommunicationSlots } from "../../actions";
import { IcuFeatureFlagsModel } from "../../models/icu.model";
import { ViewTypes } from "../../models/Authorization.model";
import IotService from "../../services/iot.service";
import { GenericSubscriptionActions, GenericSubscriptionResources } from "../../constans/genericSubscription.enum";

interface PatientViewPageProps {
    devices?: DeviceModel[];
    isDevicesObjUpdated: boolean | undefined;
    fetchFamilyRecordings: Function;
    fetchGeneralRecordings: Function;
    fetchRecordingSlots: Function;
    fetchPreCommunicationSlots: Function;
    featureFlags: IcuFeatureFlagsModel;
    views?: ViewTypes[];
}

const mapStateToProps = ({ deviceObject, icus, authInfo }: StoreState) => {
  return {
      devices: deviceObject.devices ? filterDevicesWithPatient(deviceObject.devices) : [],
      //TODO: change to general icu.
      featureFlags: icus[0]?.feature_flags,
      views: authInfo?.authorization?.views
  };
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, Action>
) => {
    return {
        fetchFamilyRecordings: (patientId: string) =>
            dispatch(fetchFamilyRecordings(patientId)),
        fetchGeneralRecordings: (patientId: string) =>
            dispatch(fetchGeneralRecordings(patientId)),
        fetchRecordingSlots: (patientId: string) =>
            dispatch(fetchRecordingSlots(patientId)),
        fetchPreCommunicationSlots: (patientId: string, slotName: string) =>
            dispatch(fetchPreCommunicationSlots(patientId, slotName))
    };
};

export const _PatientViewPage = ({
    devices,
    isDevicesObjUpdated,
    fetchFamilyRecordings,
    fetchGeneralRecordings,
    fetchRecordingSlots,
    fetchPreCommunicationSlots,
    featureFlags,
    views
}: PatientViewPageProps): JSX.Element => {
    const navigate: NavigateFunction = useNavigate();
    let params = useParams();
    const [isVideoStreamReady, setIsVideoStreamReady] = useState(false);
    const [currentDevice, setCurrentDevice] = useState<DeviceModel>();

    const videoStreamRef = useRef<any>(null);

    const findCurrentDevice = (deviceId: string | undefined) => {
        if (!deviceId && devices && devices[0]) {
            navigate(`/patientview/${devices[0].id}`);
        } else {
            return devices?.find((device) => {
                return device.id === deviceId;
            });
        }
    };

    const openVideoStream = () => {
        videoStreamRef.current.show();
    }

    const hideVideoStream = () => {
        videoStreamRef.current.hide();
    }

    useEffect(() => {
        let device = findCurrentDevice(params.deviceId);

        if (device && devices && device?.id !== currentDevice?.id) {
            console.log(device);
            setCurrentDevice(device);
            if (featureFlags?.recordings?.family_recordings_management) {
                fetchFamilyRecordings(device.patient?._id)
            }
            if (featureFlags?.recordings?.general_recordings_management) {
                fetchGeneralRecordings(device.patient?._id);
                fetchRecordingSlots(device.patient?._id);
                fetchPreCommunicationSlots(device.patient?._id, "Stimuli")
            }
            hideVideoStream();
        }
    }, [params.deviceId, devices?.length]);

    useEffect(() => {
        let subscription: ZenObservable.Subscription;
        if(params.deviceId){
            let device = findCurrentDevice(params.deviceId);
            if (device && device.patient?._id) {
                subscription = subscribeToGenericDeviceTopic(device.tenant_id, device.icu_id, params.deviceId, device.patient._id);
            }
        }
        return () => {
            if(subscription) {
                IotService.unsubscribeToTopic(subscription);
            }
        };
    }, [params.deviceId]);

    const { t } = useTranslation();

    const _reconnectDeviceGenericTopic = (
        tenantId: string, icuId: string, deviceId: string, patienId: string
    ) => {
        setTimeout(() => { 
            subscribeToGenericDeviceTopic(tenantId, icuId, deviceId, patienId);
        }, 10000);
    };

    const subscribeToGenericDeviceTopic = (tenantId: string, icuId: string, deviceId: string, patienId: string) => {
        const topic = `${tenantId}/${icuId}/${deviceId}/generic`;

        const subscription =  IotService.subscribeToTopic(topic,
            (data: any) => {
                if(data.value.action === GenericSubscriptionActions.CREATE) {
                    switch (data.value.resource) {
                        case GenericSubscriptionResources.FAMILY_MESSAGE:
                            fetchFamilyRecordings(patienId)
                            return;
                        default:
                            return;
                    }
                }
            },
            (err: any) => {
                console.log(`Error: ${JSON.stringify(err)}`);
            },
            () => {
                _reconnectDeviceGenericTopic(tenantId, icuId, deviceId, patienId);
            }
        );

        return subscription;
    }

    const updateStreamStatus = (isVideoStreamReady: boolean) => {
        setIsVideoStreamReady(isVideoStreamReady);
    }

    return (
        <div className="patient-view-page">
            <div className="patient-view-page__header-box">
                <div className="patient-view-page__title">
                    {t("Patient View")}
                </div>
                {devices?.length ? (
                    <div className="patient-view-page__header-buttons">
                        <button
                            disabled={isVideoStreamReady}
                            onClick={() => {
                                openVideoStream();
                            }}
                            className={`patient-view-page__camera-button button button-regular button-camera ${
                                isVideoStreamReady
                                    ? "button-camera--disabled"
                                    : ""
                            }`}
                        >
                            <CameraIcon color="#fff" />
                        </button>
                        <div className="patient-view-page__action-button">
                            <PetientActionMenu views={views} featureFlags={featureFlags} deviceId={params.deviceId} />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="patient-view-page__main">
                <div className="patient-view-page__side-bar">
                    {isDevicesObjUpdated ? (
                        devices?.length ? (
                            <PatientSideBar
                                devices={devices}
                                activeDeviceId={params.deviceId}
                            />
                        ) : (
                            <div className="patient-view-page__empty-message">
                                {t("No patient found")}
                            </div>
                        )
                    ) : (
                        <Skeleton
                            height="100%"
                            className="overview__data skeleton"
                        ></Skeleton>
                    )}
                </div>
                <div className="patient-view-page__information-screens">
                    <Outlet />
                </div>
            </div>
            <VideoStreamBox
                ref={videoStreamRef}
                tenantId={currentDevice?.tenant_id}
                deviceId={currentDevice?.id}
                updateStreamStatus={updateStreamStatus}
            />
        </div>
    );
};

export const PatientViewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_PatientViewPage);
