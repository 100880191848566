import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils";
import { Avatar } from "primereact/avatar";
import { DeviceModel } from "../../models/device.model";
import { LockIcon } from "../../icons/Lock";
import { LanguageIcon } from "../../icons/Language";

interface UserProfileProps {
    profile: string | undefined;
    currentDevice: DeviceModel;
    language: string;
}

export const UserProfile = ({
    profile,
    currentDevice,
    language
}: UserProfileProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="near-bed-view-page__profile-wrapper">
            <div className="near-bed-view-page__profile-info">
                <Avatar {...(profile === undefined ? { icon: "pi pi-user" } : { image: profile })} className="near-bed-view-page__profile-image p-avatar-circle" shape="circle" />
                <div className="near-bed-view-page__profile-section">
                    <div className="near-bed-view-page__full-name">
                        {currentDevice?.patient?.first_name} {currentDevice?.patient?.last_name} 
                    </div>
                    <div className="near-bed-view-page__passkey-details">
                        {/* <div className="near-bed-view-page__name">
                            {currentDevice?.patient?.first_name}
                        </div> */}
                            <div className="near-bed-view-page__details-icon">
                                <LockIcon />
                            </div>
                            {currentDevice?.patient?.family_secret} 
                    </div>
                    <div className="near-bed-view-page__profile-info-details">
                        <div className="near-bed-view-page__passkey-details">
                            <div className="near-bed-view-page__details-icon">
                                <LanguageIcon />
                            </div>
                            {capitalizeFirstLetter(language)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="near-bed-view-page__profile-details">
            
            </div>
        </div>
    );
};
