import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import {
    SquareIcon,
    PlayIcon,
    ArrowDownloadIcon,
    ArchiveIcon,
} from "../../icons";
import Moment from "react-moment";
import { downloadStream, formatFloatAsTime } from "../../utils";
import {
    GeneralRecordingModel,
    NOT_PLAYING,
} from "../../models/recording.model";
import { useTranslation } from "react-i18next";
import { TOAST_SEVERITY } from "../../models/toast.model";
import Timer from "../general/Timer";
import { playRecordings, togglePlaying } from "../../utils/playerMethods";

interface GeneralRecordingsProps {
    recordings: GeneralRecordingModel[];
    patientId: string;
    showToast: Function;
}

const GeneralRecordings: React.FC<GeneralRecordingsProps> = ({
    recordings,
}: GeneralRecordingsProps) => {
    const { t } = useTranslation();

    const [playingArray, setPlayingArray] = useState<boolean[]>([]);
    const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
    const [playingIndex, setPlayingIndex] = useState<number>(NOT_PLAYING);

    const downloadGeneralRecording = (
        links: any[] | undefined,
        paths: string[]
    ) => {
        links?.forEach((link, index) => {
            downloadStream(link, paths[index]);
        })
    };

    useEffect(() => {
        setPlayingArray(Array(recordings.length).fill(false));
    }, []);

    useEffect(() => {
        return () => {
            if (audio) {
                audio?.pause();
                audio.src = "";
                setAudio(null);
            }
        };
    }, [recordings, audio]);

    return (
        <ul className="recordings-list__list">
            {recordings.map((recording, index) => {
                return (
                    <li className={"recordings-list__item"} key={recording.id}>
                        <div className="recordings-list__item-start">
                            <div className="recordings-list__play">
                                <Button
                                    rounded
                                    
                                    onClick={() =>
                                        togglePlaying(
                                            index,
                                            setPlayingArray,
                                            audio,
                                            playRecordings,
                                            recordings,
                                            setAudio,
                                            setPlayingIndex,
                                            togglePlaying,
                                            index
                                        )
                                    }
                                    className="recording-general__play-button button"
                                    disabled={playingArray.some(
                                        (p, i) => p && i !== index
                                    ) || recording.links == undefined}
                                    icon={
                                        playingArray[index] ? (
                                            <SquareIcon />
                                        ) : (
                                            <PlayIcon />
                                        )
                                    }
                                ></Button>
                            </div>
                            <div className="recordings-list__name">
                                <div className="recordings-list__name-text">
                                    {recording.name}
                                </div>
                            </div>
                            <div className="recordings-list__duration">
                                {playingArray[index] ? (
                                    <Timer
                                        isRunning={playingArray[index]}
                                        timeLimit={
                                            recording.duration !== "N/A"
                                                ? parseFloat(recording.duration)
                                                : 100000
                                        }
                                    />
                                ) : recording.duration !== "N/A" ? (
                                    formatFloatAsTime(
                                        parseFloat(recording.duration) / 60
                                    )
                                ) : (
                                    t(`None`)
                                )}
                            </div>
                            <div className="recordings-list__date">
                                <Moment format="MMM Do, YYYY HH:mm">
                                    {recording.creation_date}
                                </Moment>
                            </div>
                        </div>
                        <div className="recordings-list__item-end">
                            <div
                                onClick={() =>
                                    downloadGeneralRecording(
                                        recording.links,
                                        recording.path
                                    )
                                }
                                className="recordings-list__download"
                            >
                                <ArrowDownloadIcon />
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default GeneralRecordings;
