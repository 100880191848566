import { Dispatch } from "redux";
import { IcuModel, IcuSettingsModel } from "../models/icu.model";
import { IcuActionTypes } from ".";
import icusApi from '../apis/icus';

export interface FetchIcuAction {
    type: IcuActionTypes.FETCH_ICUS;
    payload: IcuModel[];
}

export interface IcuSettingsAction {
    type: IcuActionTypes.SET_ICU_SETTINGS;
    payload: {
        icuId: string,
        icuSettings: IcuSettingsModel
    };
}

export type IcuAction = FetchIcuAction | IcuSettingsAction;

export const getIcus = (jwtToken: string) => {
    return async (dispatch: Dispatch) => {        
        const response = await icusApi.get("/", {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            }
        });

        if (response.status === 200) {
            setIcusState(dispatch, response.data);
        }
    }
}

export const setIcuSettings = (settings: any, icuId: string) => {
    return async (dispatch: Dispatch) => {
        const response = await icusApi.patch(`${icuId}`, {settings});

        if (response.status === 200) {
            _setIcusSettings(dispatch, settings, icuId);
        }
    }
}

const setIcusState = (dispatch: Dispatch, icus: IcuModel[] ) => {
    dispatch<IcuAction>({
        type: IcuActionTypes.FETCH_ICUS,
        payload: icus,
    });
}

const _setIcusSettings = (dispatch: Dispatch, icuSettings: IcuSettingsModel, icuId: string ) => {
    dispatch<IcuAction>({
        type: IcuActionTypes.SET_ICU_SETTINGS,
        payload: {icuId, icuSettings}
    });
}