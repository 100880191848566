import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface UserMenuProps {
  user: { name: string; email: string; imgUrl: string };
  op: RefObject<OverlayPanel>;
  actions: { logout: Function }
}

export const UserMenu = ({ user, op, actions }: UserMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate: NavigateFunction = useNavigate();

  const goToKnowledgeCenter = () => {
    window.open('//med-support.eyecontrol.co.il/portal/en/kb/eyecontrol-med', '_blank');
  };

  return (
    <OverlayPanel className="user-menu__overview-panel" ref={op}>
      <div className="user-menu">
        <div className="user-menu__top">
          {/* <Avatar
            className="user-menu__profile-image p-avatar-circle"
            image={user.imgUrl}
          /> */}
          <Avatar icon="pi pi-user" className="user-menu__profile-image p-avatar-circle" size="large" shape="circle" />
          <div className="user-menu__info-box">
            <div className="user-menu__name">{user.name}</div>
            <div className="user-menu__email">{user.email}</div>
          </div>
        </div>
        <div className="user-menu__bottom">
          <ul className="user-menu__list">
            <li onClick={() => goToKnowledgeCenter()} className="user-menu__item">{t("Knowledge Center")}</li>
            <li onClick={() => {actions.logout(navigate)}} className="user-menu__item">{t("Log Out")}</li>
          </ul>
        </div>
      </div>
    </OverlayPanel>
  );
};
