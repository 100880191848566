import { connect } from "react-redux";
import { StoreState } from "../../reducers";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import {
    updateToast,
    uploadGeneralRecordings,
} from "../../actions";
import { TOAST_SEVERITY } from "../../models/toast.model";
import { Button } from "primereact/button";
import { useState, useRef } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { ResourcePermissionModel, ResourceAction } from "../../models/Authorization.model";
import { checkResourcePermission } from "../../utils";


interface GeneralUploadButtonProps {
    patientId: string;
    showToast: Function;
    uploadGeneralRecordings: Function;
    currentPermissionResource?: ResourcePermissionModel;
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, void, Action>
) => {
    return {
        uploadGeneralRecordings: (
            patientId: string,
            generalFiles: File[],
            callback: Function
        ) =>
            dispatch(
                uploadGeneralRecordings(patientId, generalFiles, callback)
            )
    };
};

const _GeneralUploadButton = ({
    patientId,
    showToast,
    uploadGeneralRecordings,
    currentPermissionResource
}: GeneralUploadButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const AUDIO_FORMATS = ".mp3,.ogg,.m4a,.wav";

    const uploadFiles = (event: any) => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
        event.stopPropagation();
        event.preventDefault();
    };

    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files && event.target.files?.length !== 0) {
            const generalFiles = Array.from(event.target.files);

            showToast({
                severity: TOAST_SEVERITY.INFO,
                summary: t("Uploading"),
                detail: t("Uploading, Please wait..."),
            });

            setSubmitting(true);
            uploadGeneralRecordings(
                patientId,
                generalFiles,
                (result: TOAST_SEVERITY, error: string) => {
                    setSubmitting(false);
                    showToast({
                        severity: result,
                        summary:
                            result === TOAST_SEVERITY.SUCCESS
                                ? t("Success")
                                : t("Unable to upload recordings"),
                        detail:
                            result === TOAST_SEVERITY.SUCCESS
                                ? t(
                                      `The ${
                                          generalFiles.length === 1
                                              ? "file was"
                                              : "files were"
                                      } uploaded successfully.`
                                  )
                                : error
                                ? t(`${error}`)
                                : t(
                                      `The ${
                                          generalFiles.length === 1
                                              ? "file was"
                                              : "files were"
                                      } not uploaded successfully.`
                                  ),
                    });
                    (fileInputRef.current as HTMLInputElement).value = "";
                }
            );
        }
    };

    return (
        <div className="general-recording-button">
            <Button
                onClick={(event) => {
                    uploadFiles(event);
                }}
                className={`recordings-list__upload-button button button-regular ${
                    submitting ? "button-disabled" : ""
                }`}
                disabled={submitting || !checkResourcePermission(currentPermissionResource, ResourceAction.CREATE)}
            >
                {submitting ? (
                    <div className="button-loading">
                        <ProgressSpinner />
                    </div>
                ) : (
                    t("Upload")
                )}
            </Button>
            <input
                accept={AUDIO_FORMATS}
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                multiple
                onChange={handleFileChange}
            />
        </div>
    );
};

export const GeneralUploadButton = connect(null, mapDispatchToProps)(_GeneralUploadButton);
