import {BrowserRouter, Routes, Route, Navigate, Outlet} from "react-router-dom";
// import { PrivateRoute } from "../components/authentication/PrivateRoute";
import { Navbar } from "../components/general/Navbar";
import { OverviewPage } from "./pages/OverviewPage";
import { PatientViewPage } from "../components/pages/PatientViewPage";
import { SettingsPage } from "../components/pages/SettingsPage";
import { DevicesSettings } from "../components/pages/settings/DevicesSettings";
import { NotFoundPage } from "../components/pages/NotFoundPage";
import { Main } from "../components/general/Main";
import { ScrollingSidebar } from "../components/general/ScrollingSidebar";
import "../scss/components/App.scss";
import { DeviceLanguage } from "./general/settings/DeviceLanguage";
import { useTranslation } from "react-i18next";
import { IcuLanguage } from "./general/settings/IcuLanguage";
import { Login } from "./authentication/Login";
import { connect } from "react-redux";
import { StoreState } from "src/reducers";
import { DeviceModel } from "../models/device.model";
import { DeviceEarphoneVolume } from "./general/settings/DeviceEarphoneVolume";
import { BroadcastMethodCard } from "./general/settings/BroadcastMethodCard";
import { PatientInformationScreen } from "./../components/patientView/PatientInformationScreen";
import { CustomDialog, OpenDialogArgs } from "./general/CustomDialog";
import { useRef } from "react";
import { DialogContext } from "./../contexts/DialogContext";
import { filterDevicesWithPatient, isEnableView } from "../utils";
import { CameraOopIndicatiors } from "./general/settings/CameraOopIndicatiors";
import { DeviceSettingsHeader } from "./deviceView/DeviceSettingsHeader";
import { SchedulerCard } from "./general/settings/SchedulerCard";
import { DeviceList } from "./general/DeviceList";
import { SCHEDULER_VIEW } from "../models/scheduler.model";
import { Skeleton } from 'primereact/skeleton';
import { IcuModel } from "../models/icu.model";
import { AuthorizationPageWrapper } from "./authentication/AuthorizationPageWrapper";
import { NearBedViewPage } from "./pages/NearBedViewPage";
import { DeviceInitiationBehavior } from "src/components/general/settings/DeviceInitiationBehavior";
import { ViewTypes } from "src/models/Authorization.model";

interface AppProps {
    devices?: DeviceModel[];
    isAuthenticated?: boolean;
    icus?: IcuModel[];
    isDevicesObjUpdated?: boolean;
    views? : ViewTypes[];
}

const mapStateToProps = ({ deviceObject, authInfo, icus }: StoreState) => {
    return {
        devices: deviceObject.devices,
        isAuthenticated: !!authInfo?.currentUser?.token,
        icus,
        isDevicesObjUpdated: deviceObject?.isDevicesObjUpdated,
        views: authInfo?.authorization?.views
    };
};

const _App = ({ devices, isAuthenticated, icus, isDevicesObjUpdated, views }: AppProps): JSX.Element => {
    const { t } = useTranslation();
    // TODO: change in the future the type to be specific.
    const dialogRef = useRef<any>(null);
    const attentionDialogRef = useRef<any>(null);

    const isNurseView = isEnableView(ViewTypes.NURSE, views);

    let scrollingSidebarObj = {
        title: t("ICU settings"),
        subTitle: t(
            "This settings are global and will affect all EyeControl devices that are associate with your facility. In this section you can define the default settings for your devices."
        ),
        sections: [
            { name: t("Communication"), element: <IcuLanguage /> },
            { name: t("Scheduler"), element: <SchedulerCard /> }
        ],
    };

    let deviceObj = {
        title: t("List of Devices"),
        subTitle: t(""),
        headerComponent: <DeviceSettingsHeader />,
        sections: [
            { name: t("Communication"), element: <DeviceLanguage /> },
            { name: t("broadcastMethod"), element: <BroadcastMethodCard /> },
            { name: t("Earphone Volume"), element: <DeviceEarphoneVolume /> },
            { name: t("cameraOopIndicatiors"), element: <CameraOopIndicatiors /> },
            { name: t("deviceInitiationBehavior"), element: <DeviceInitiationBehavior /> },
            { name: t("Scheduler"), element: <SchedulerCard schedulerView={SCHEDULER_VIEW.DEVICE} /> },
        ],
    };

    let skeletonObj = {
        title: t("Loading..."),
        subTitle: t(""),
        headerComponent: <Skeleton height="15rem" className="skeleton" />,
        sections: [
            { name: t(""), element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> },
            { name: t(""), element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/>},
            { name: t(""), element: <Skeleton height="30rem" width="64.7rem" className="mb-4 skeleton"/> }
        ],
    };

    return (
        // TODO: Add route gaurd
        <div className="container">
            <DialogContext.Provider
                value={{
                    showDialog: (args: OpenDialogArgs) => {
                        dialogRef?.current?.showDialog(args);
                    },
                    hide: () => dialogRef?.current?.hide(),
                    resetTimeOut: () => dialogRef?.current?.resetTimeOut(),
                    updateArguments: (args) => dialogRef?.current?.updateArguments(args),
                    showAttentionDialog: (args: OpenDialogArgs) => {
                        attentionDialogRef?.current?.showDialog(args);
                    },
                    hideAttentionDialog: () => attentionDialogRef?.current?.hide(),
                    resetAttentionTimeOut: () => attentionDialogRef?.current?.resetTimeOut(),
                    updateAttentionArguments: (args) => attentionDialogRef?.current?.updateArguments(args)
                }}
            >
                <BrowserRouter>
                    {isAuthenticated && <Navbar />}
                    <Main>
                        <Routes>
                            <Route index element={<Navigate to={isAuthenticated ? "/overview" : "/login"} replace />}/>
                            <Route path="/login" element={<Login />} />
                            <Route path="/" element={isAuthenticated ? <Outlet /> : <Navigate to="/login" />}>
                                <Route path="overview"
                                       element={
                                           <OverviewPage devices={filterDevicesWithPatient(devices)}
                                                         isDevicesObjUpdated={isDevicesObjUpdated}/>
                                       }
                                />
                                <Route
                                    path="patientview"
                                    element={
                                        <AuthorizationPageWrapper pageName="PatientView">
                                            <PatientViewPage isDevicesObjUpdated={isDevicesObjUpdated}/>
                                        </AuthorizationPageWrapper>
                                    }
                                >
                                    <Route path=":deviceId" element={<PatientInformationScreen />} />
                                </Route>
                                <Route
                                    path="neartobedpatientview"
                                    element={
                                        <AuthorizationPageWrapper pageName="NearToBedPatientView">
                                            <NearBedViewPage isDevicesObjUpdated={isDevicesObjUpdated}/>
                                        </AuthorizationPageWrapper>
                                    }
                                >
                                    <Route path=":deviceId" element={<PatientInformationScreen />} />
                                </Route>
                                <Route path="settings" element={<SettingsPage />}>
                                    <Route index element={<Navigate replace to="icu" />} />
                                    <Route
                                        path="icu"
                                        element={
                                            <ScrollingSidebar
                                                devices={[]}
                                                scrollingSidebarObj={
                                                    icus?.length
                                                        ? scrollingSidebarObj
                                                        : skeletonObj
                                                }
                                            />
                                        }
                                    />
                                    <Route path="devices" element={<DevicesSettings/>}>
                                        <Route
                                            path=""
                                            element={
                                                <DeviceList isDevicesObjUpdated={isDevicesObjUpdated} devices={devices}/>
                                            }
                                        />
                                        <Route
                                            path=":deviceId"
                                            element={
                                                <ScrollingSidebar
                                                    devices={filterDevicesWithPatient(devices)}
                                                    scrollingSidebarObj={devices?.length ? deviceObj : skeletonObj}
                                                />
                                            }
                                        />
                                    </Route>
                                    <Route path="*" element={<NotFoundPage />} />
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Main>
                    <CustomDialog
                        reopenTime={icus ? icus[0]?.settings?.dialog_reopen_time : undefined}
                        ref={dialogRef}
                        additinalClass={isNurseView ? "nurse-view" : ""}
                    />
                    <CustomDialog
                        reopenTime={icus ? icus[0]?.settings?.dialog_reopen_time : undefined}
                        ref={attentionDialogRef}
                        additinalClass={isNurseView ? "nurse-view" : ""}
                    />
                </BrowserRouter>
            </DialogContext.Provider>
        </div>
    );
};

export const App = connect(mapStateToProps, null)(_App);
